import { EncryptedCookieStorageService } from './../services/encrypted-cookie-storage.service';
import { Injectable } from "@angular/core";
import { interval, Observable, Observer } from "rxjs";
import { Router } from "@angular/router";
import { SecureSimpleStorage } from "src/data/disk-data-source/secure-simple-storage";
import { Contract } from "src/data/disk-data-source/contract";
import { LoginRes } from "../entities/loginFG";
import { LoadingService } from "./loading.service";
import { SweetAlert } from "./sweetAlert";
import { Constants, environment } from "src/environments/environment";
import { LogoutAPIService } from "../services/logoutAPI.service";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { LoginService } from '../services/login.service';
import { RegisterUserService } from '../services/RegisterUser.service';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  timer;
  data: any;
  user: LoginRes;
  public appPages: any;
  loadingSesion: boolean = false;
  isLoading: boolean;

  constructor(
    public router: Router,
    private secureStorage: SecureSimpleStorage,
    public loading: LoadingService,
    private sweet: SweetAlert,
    private out: LogoutAPIService,
    private modal: ModalController,
    private toastCtrl: ToastController,
    private encryptedCookieStorageService: EncryptedCookieStorageService,
  ) {
    this.user = {
      acount: false,
      email: "",
      picture: "",
      user: "Usuario",
    };
  }

  refreshExpirationLogin() {
    this.isLogged().subscribe((result) => {
      if (result) {
        this.setIntervalLogin();
      }
    });
  }

  isLogged(): Observable<any> {
    return new Observable((observer: Observer<boolean>) => {
      if(environment.rol == 'Individual' || environment.rol == 'Nuevo estandar' || environment.rol == 'Empresarial'){
        observer.next(false);
      } else {
        this.secureStorage.get(Contract.user).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          () => {
            observer.next(false);
            observer.complete();
          }
        );
      }    
    });
  }

  login(usuario): Observable<any> {
    this.setIntervalLogin();
    this.makeMenu(true, usuario);
    return this.secureStorage.save(Contract.user, JSON.stringify(usuario));
  }

  logout(): Observable<any> {

    //Limpieza de cookie de login
    this.encryptedCookieStorageService.remove(
      Contract.userCredential,
      '.forzadelivery.com'
    );
    this.encryptedCookieStorageService.remove(
        Contract.passwordCredential,
        '.forzadelivery.com'
    );
    localStorage.clear();

    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.loadingSesion = false;
    this.modal.dismiss().catch(() => {});

    this.updateVersion();

    if (environment.rol === "Corporativo") {
      this.router.navigate(["/login-corporate"]);
    } else if(environment.rol === "Express"){
      this.router.navigate(["/login-exc"]);
    } else {
      this.router.navigate(["/login"]);
    }
    return this.secureStorage.remove([Contract.fact]);
  }

  public setIntervalLogin() {
    if (this.timer) {
      this.timer.unsubscribe();
    }

    let count = 0;

    let time = 0; //Variable para determinar el tiempo de la sesión
    if(environment.rol == "Express")
    {
      time = Constants.sesionTimeExpress;
    }
    else
    {
      time = Constants.sesionTime;
    }
    
    this.timer = interval(time).subscribe(() => {
      if (count === 0) {
        this.sweet.warningAlert("Sesión caducada", "¿ Desea extender su sesión ?", 60000).then((result) => {
          if (result.isConfirmed) {
            this.refreshExpirationLogin();
          } else if (result.isDismissed) {
            this.setLogout();
          }
        });
      }

      count++;

      if (count === 10) {
        count = 1;
      }
    });
  }

  makeMenu(logged: boolean, menu: any) {
    if (logged) {
      this.appPages = menu.ObjectValue[0].Modules;
    } else {
      this.appPages = [];
    }
  }

  verifyData() {
    this.secureStorage.get(Contract.user).subscribe(
      (data) => {
        this.data = JSON.parse(data);
      },
      (error) => {}
    );
  }

  setLogout = (message?: string, method?: string) => {
    this.verifyData();

    if (!this.loadingSesion) {
      this.loadingSesion = true;
      this.loading.showLoader(message ? message : "Cerrando sesión...").subscribe(() => {
        let body = {
          path: "Login/SetLogout",
          data: {
            Method: "SetLogout",
            Params: {
              Token: this.data.ObjectValue[0].Token,
            },
          },
        };
        this.out.postData(body).subscribe(
          (response: any) => {
            this.loading.hideLoader().subscribe();
            this.logout();
          },
          (error) => {
            this.loadingSesion = false;
            this.loading.hideLoader().subscribe();
            if (!method) {
              this.sweet
                .warningAlert("Ocurrió un error al cerrar sesión, intente de nuevo", "¿ Desea extender su sesión ?", 60000)
                .then((result) => {
                  if (result.isConfirmed) {
                    this.refreshExpirationLogin();
                  } else if (result.isDismissed) {
                    this.setLogout();
                  }
                });
            } else if (method === "GetDynamicCatalog") {
              this.setLogout(message, method);
            }
          }
        );
      });
    }
  };

  updateVersion(path?: string) {
    let body = {
      path: "Portal/GetConfigParams",
      data: {
        Method: "GetConfigParams",
        Params: {
          Name: "PortalVersion",
        },
      },
    };

    this.out.postData(body).subscribe(
      (response: any) => {
        const versionBD = response.ObjectValue[0].Value; // Version registrada en base de datos
        this.secureStorage.save(Contract.versionPortal, versionBD).subscribe();
        
        if (versionBD !== environment.version) {
          if (environment.test !== "local") {
            this.showToastReload(path);
          }
        }
      },
      (error) => {}
    );
  }

  async getImages(idCountry: string = null) {
    let body = {
      path: "Portal/GetConfigParams",
      data: {
        Method: "GetConfigParams",
        Params: {
          Name: "Images",
          IdCountry: idCountry
        },
      },
    };
    return this.out.postData(body).toPromise();
  }

  async showToastReload(path?: string) {
    const toast = await this.toastCtrl.create({
      message: "Se ha detectado una nueva versión, espere unos momentos mientras se actualiza.",
      position: "top",
      duration: 3000,
      cssClass: "version",
    });

    await toast.present();

    let usertype = "";
    this.secureStorage.get(Contract.user).subscribe((data) => {
      const user = JSON.parse(data);
      usertype = user.ObjectValue[0].Accounts[0].TacName;
    });

    toast.onDidDismiss().then(() => {
      localStorage.clear();
      if (environment.rol === 'Corporativo') {
        window.location.href = '/login-corporate';
      } else if(environment.rol === "Express"){
        window.location.href = '/login-exc';
      } else {
        window.location.href = '/login';
      }

      //Limpieza de cookie de login
      this.encryptedCookieStorageService.remove(Contract.userCredential, '.forzadelivery.com');
      this.encryptedCookieStorageService.remove(Contract.passwordCredential, '.forzadelivery.com');
    });
  }

}

export interface menu {
  Module: string;
  Icon: string;
  Path: string;
  Rol: string;
  SubModule?: menu[];
}
