/**
 * Define the name of keys for simple storage
 */
export enum Contract {
  token = "token",
  user = "user",
  addr = "addr",
  destinyAddress = "destinyAddress",
  selectaddr = "selectaddr",
  selectaddrdes = "selectaddrdes",
  ManualDestino = "ManualDestino",
  fact = "fact",
  shr = "shr",
  step1 = "shipping",
  step2 = "details",
  step3 = "guia",
  step4 = "facturacion",
  ManualOrigen = "ManualOrigen",
  cotizacion = "cotizacion",
  pickup = "pickup",
  pagoahora = "pagoahora",
  pagoreco = "pagoreco",
  pagocollect = "pagocollect",
  Totalpagoahora = "Totalpagoahora",
  guiasprocesadas = "guiasprocesadas",
  userFac = "userFac",
  pagoreco_validador = "pagoreco_validador",
  PortafolioClient = "PortafolioClient",
  clientSelected = "clientSelected",
  setServiceRecolect = "setServiceRecolect",
  updateVersion = "updateVersion",
  versionPortal = "versionPortal",
  modalUrl = "modalUrl",
  provinces = "provincias",
  isreturn = 'devoluciones',
  couponData = 'cupon',
  setServiceGuideData = 'SetServiceRecollectData',
  listadoExpressCenter = "listadoExpressCenter",
  routeActive = 'routeActive',
  queryParamsRouteActive = 'queryParamsRouteActive',
  suscripcion = 'suscripcion',
  loginCredentials = 'loginCredentials',
  userCredential = 'userCredential',
  passwordCredential = 'passwordCredential',
  countrySelected = 'countrySelected',
  stationSelected = 'stationSelected',
  multiCountryData = 'multiCountryData',
}
